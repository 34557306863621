import { Controller as BaseController } from "@hotwired/stimulus";
import $ from "jquery";

export class Controller extends BaseController {
  static targets = ["foldableCardContent", "dropDownButton"]

  toggleFoldableCard() {
    const $content = $(this.foldableCardContentTarget);

    if (this.foldableCardContentTarget.classList.contains("hidden-field")) {
      this.dropDownButtonTarget.classList.add("foldable-card-rotate-180")
      $content.slideDown(300, () => {
        this.foldableCardContentTarget.classList.remove("hidden-field")
      });
    }
    else {
      $content.slideUp(250, () => {
        this.foldableCardContentTarget.classList.add("hidden-field")
      });
      this.dropDownButtonTarget.classList.remove("foldable-card-rotate-180")
    }
  }
}